import { React } from 'lib'
import { Settings } from 'app'

export default function LegalHeader() {
  return (
    <>
      <a href={'/'}><h1>Becon App</h1></a>
      {/* <h4>Becon App terms of use</h4> */}
      {/* <p>Thank you for using our app!</p> */}
      <p>This document contains information about the Becon Mobile App:</p>
      {/* <h5>In short...</h5> */}

      <a href={'#TERMS OF USE'}><h4>1. TERMS OF USE</h4></a>
      <a href={'#PRIVACY POLICY'}><h4>2. PRIVACY POLICY</h4></a>
      <br/>
    </>
  )
}
